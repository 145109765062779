
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CustomGenericTable } from '../../../../../componentes/Tables/CustomTable'
import { columnSize } from '../../../../../Constantes';
import { ObtenerInsumos } from '../../../../../Apis/FetchCatalogos';
import CircleSpiner from '../../../../../componentes/Spinners/CircleSpiner';
import { CustomInputText } from '../../../../../componentes/Formik/InputText';

export default function InsumosTable({ autoLoad = true, isEditable = false, isSelected = false, multiSelected = false, setDataRowSelection, customColumnFilter }) {
    const [loadingPage, setLoadingPage] = useState(false);

    //INSUMOS
    const [insumos, setInsumos] = useState([]);
    const [rowSelectionInsumos, setRowSelectionInsumos] = useState({});
    const [columnCustomFiltersInsumos, setCustomColumnFiltersInsumos] = useState("");
    //const [dataRowSelectionInsumos, setDataRowSelectionInsumos] = useState({});

    const msjErrorQuantity = 'La cantidad debe ser mayor a 0';

    const opcionesTablaInsumos = { ofText: "Insumos de" };
    const columnasDataInsumos = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "Id",
                enableColumnFilter: false
                //footer: props => props.column.id,
                //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
            },
            {
                accessorKey: "clave",
                header: "Clave",
                enableColumnFilter: true,
                filterFn: "arrIncludesSome", //note: normal non-fuzzy filter column - case insensitive
                //filterFn: 'customFilter', //using our custom function filter
                //filterFn: "fuseFilterFn"
                //footer: props => props.column.id,
                size: 50, //set column size for this column
                enableSorting: true
            },
            {
                accessorKey: "nombre",
                header: "Nombre",
                enableColumnFilter: false,
                size: columnSize, //set column size for this column
                enableSorting: false
                //footer: props => props.column.id,
            },
            {
                accessorKey: "cantidad",
                header: "cantidad",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100 //set column size for this column
                //footer: props => props.column.id,
            },
            {
                accessorKey: "existencias",
                header: "Existencias",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100 //set column size for this column
                //footer: props => props.column.id,
            },
            {
                accessorKey: "modelo",
                header: "Modelo",
                enableColumnFilter: false,
                enableSorting: false,
                //footer: props => props.column.id,
                size: 100 //set column size for this column
            },
            {
                accessorKey: "unidad",
                header: "Uni",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100 //set column size for this column
                //footer: props => props.column.id,
            },
            {
                accessorKey: "tipo",
                header: "Tipo",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100 //set column size for this column
                //footer: props => props.column.id,
            }
        ],
        []
    );

    /**
* Funcion que valida si el numero ingresado es correcto, establece formato para la celda y Flag del mensaje de error
* @param {*} e evento onValueChange
* @param {*} setOnError hook para controlar la bandera de error sobre la celda o campo
*/
    const listenerValidaNumber = (e, setOnError) => {
        let isDataValid = false;
        let curval = Number(e.target.value);
        setTimeout(function () {
            // revisar si el valor de dias es mayor a 0 y menor a los dias establecidos en la ruta
            if (!isNaN(curval) && curval > 0) {
                isDataValid = true;
            }
            if (isDataValid) {
                console.log("valor numerico permitido");
                e.target.classList.remove("border-[1px]");
                e.target.classList.remove("border-red-600");
            } else {
                console.log(
                    "valor no permitido. La cantidad debe ser numerico y mayor a 0"
                );
                e.target.innerHTML = "";
                e.target.classList.add("border-[1px]");
                e.target.classList.add("border-red-600");
                // CustomToastify({
                //     title: "Cantidad incorrecta",
                //     message: "La cantidad debe ser mayor a 0",
                //     type: "warning"
                // });
            }
            setOnError(!isDataValid);
        }, 100);
    };

    const TableCell = (getValue, row, id, table) => {
        const [value, setValue] = useState("");
        const [onError, setOnError] = useState(false);
        let initialValue = getValue();

        const isCantidad = id === "cantidad";
        // When the input is blurred, we'll call our table meta's updateData function
        const onBlur = () => {
            //console.log(getValue, row, id, table, value)
            table.options.meta?.updateData(row.index, id, value);
            //dataRowSelection.some( ins => ins.id === )
            //if(dataRowSelection[0] && (row.original.id === dataRowSelection[0].id)){
            setRowSelectionInsumos((prevstate) => ({
                ...prevstate,
                [row.index]: true
            }
            ))
        };
        //establecer el valor de inicio, cuando se recuperan del servicio
        useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        //dependiendo de la validacion se setea como input o no
        return isCantidad ? (
            <>
                <input
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        listenerValidaNumber(e, setOnError);
                    }}
                    onBlur={onBlur}
                    type={"number"}
                    className={`${onError ? 'dataError' : ''}`}
                />
                {
                    onError ?
                        <span className="dataError-Msj">{msjErrorQuantity}</span>
                        : ''
                }
            </>
        ) : (
            <span>{value}</span>
        );
    };
    const defaultColumnInsumos = {
        cell: ({ getValue, row, column: { id }, table }) => {
            return TableCell(getValue, row, id, table);
        }
    };

    const GetDataInsumos = useCallback(async () => {
        console.log('loading insumos')
        setLoadingPage(true);
        const data = await ObtenerInsumos();
        let cInsumos = [];
        if (data !== null && data.length > 0) {
            cInsumos = data.map((res) => {
                //console.log(res.clave,salidas, xSalida)
                const insumo = {
                    id: res.id,
                    clave: res.clave,
                    nombre: res.nombre,
                    modelo: res.modelo,
                    tipo: res.tipo,
                    existencias: res.existen ?? "",
                    unidad: res.unidad,
                    cantidad: 0
                };
                //si hay salidas, las mantiene seleccionadas cada que se carguen los insumos
                // if(xSalida.length >0){
                //     setRowSelection((prevstate) => ({
                //         ...prevstate,
                //         [index]: true
                //     }
                //     ))
                // }
                return insumo;
            });
        }
        setInsumos(cInsumos);
        setLoadingPage(false);
    }, []);

    useEffect(() => {
        if (autoLoad){
            GetDataInsumos();
        }
    }, [autoLoad]);

    return (
        <>
            {(loadingPage) && <CircleSpiner />}
            {!customColumnFilter &&
                <div className="w-full md:w-1/4 mb-2">
                    <CustomInputText
                        disabled={loadingPage}
                        label="Buscar por clave"
                        name="claveSearch"
                        type="text"
                        placeholder=""
                        onChange={async (e) => {
                            const filterInput=e.target.value
                            const filterValues = filterInput.split(',').map(value => value.trim());
                            let filterArray = filterValues.filter( v => v !=='')
                            if(filterArray.length > 0)
                                setCustomColumnFiltersInsumos([
                                    {
                                        id: "clave",
                                        value: filterArray
                                    }
                                ]);
                            else setCustomColumnFiltersInsumos('');
                        }}
                    />
                </div>
            }
            <CustomGenericTable
                columnsData={columnasDataInsumos}
                data={insumos}
                optionsTable={opcionesTablaInsumos}
                setData={setInsumos}
                getData={GetDataInsumos}
                defaultColumn={isEditable ? defaultColumnInsumos : {}}
                columnCustomFilters={customColumnFilter ? customColumnFilter : columnCustomFiltersInsumos}
                isTableSelected={isSelected}
                isMultiRowSelection={multiSelected ?? false}
                rowSelection={rowSelectionInsumos}
                setRowSelection={setRowSelectionInsumos}
                setDataRowSelection={setDataRowSelection}
            />
        </>

    )
}
