import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";
const MSJ_CORREO_INVALIDO = "Correo inválido";

const schemaActualizarCliente = Yup.object().shape({
    clave: Yup.string().required(MSJ_DATO_REQ), // Campo obligatorio
    fnombre: Yup.string().required(MSJ_DATO_REQ), // Campo obligatorio
    regimenfiscal: Yup.string().required(MSJ_DATO_REQ), // Campo obligatorio
    usocfdi: Yup.string().required(MSJ_DATO_REQ), // Campo obligatorio
    rfc: Yup.string().required(MSJ_DATO_REQ), // Campo obligatorio
    telefono: Yup.string().required(MSJ_DATO_REQ), // Campo obligatorio
    contacto: Yup.string().required(MSJ_DATO_REQ), // Campo obligatorio
    status: Yup.string().required(MSJ_DATO_REQ), // Campo obligatorio
    // Agrega más campos según sea necesario
    // Ejemplo de un campo opcional
    refbanco: Yup.string().required(MSJ_DATO_REQ), // Campo opcional
    // Puedes agregar validaciones adicionales para otros campos según sea necesario
});

export default schemaActualizarCliente;