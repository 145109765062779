import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
// Initialization for ES Users
import { Select } from "tw-elements";
import Accordion from "../../../../componentes/Accordion/Accordion";
import { InputText, Select as SelectF } from "../../../../componentes/Formik";
import { BuscarClientePorClave, ActualizarCliente } from "../../../../Apis/FetchClientes";
import schemaActualizarCliente from "./Schemas/schemaActualizarCliente"; // Ajusta la ruta según sea necesario
import { useExecutePromise } from "../../../../Hooks/UsePromiseAction";
import CircleSpiner from "../../../../componentes/Spinners/CircleSpiner";
import useServicios from "../../../../Hooks/UseServicios";
import useFamilias from "../../../../Hooks/UseFamilias";
import { agSelectOptRutaPlanta } from "../Alta/AltaCliente";
import { ObtenerRutasPorPlanta } from "../../../../Apis/FetchCatalogos";
//import { sleep } from "../../../../utils/CommonUtils";


const ActualizarClienteForm = () => {
    const { runAction, loadingAction } = useExecutePromise(); // Inicializa el hook
    const [loadingData, setLoadingData] = useState(false);
    const [notFoundedData, setNotFoundedData] = useState(true)
    const [rutasP, setRutasP] = useState([]);

    const {
        servs, agSelectOptServ,
    } = useServicios("selectServ")
    const {
        familias, agSelectOptFam
    } = useFamilias("selectFam")


    const [initialValues, setInitialValues] = useState({
        clave: '',
        unidadnegocio: '',
        fnombre: '',
        tiposociedad: '',
        regimenfiscal: '',
        usocfdi: '',
        correoelectronico: '',
        segundocorreoelectronico: '',
        ruta: '',
        orden: '',
        aalta: '',
        condspago: '',
        rfc: '',
        revision: '',
        pago: '',
        status: '',
        registro: '',
        contratos: '',
        conts: '',
        paqinsm: '',
        rutaplanta: "",
        replegal: '',
        nombre: '',
        numerocta: '',
        mtdopago: '',
        calle: '',
        colonia: '',
        ciudad: '',
        estado: '',
        cp: '',
        entreqcalles: '',
        telefono: '',
        servicio: '',
        contacto: '',
        refbanco: '', // Asegúrate de que este campo esté aquí
        fcalle: '',
        fcolonia: '',
        fciudad: '',
        festado: '',
        fcp: '',
        ftelefono: '',
        fcontacto: '',
        frec: '',
        fam: ''
    });

    const handleKeyDown = async (e, setFieldValue) => {

        if (e.key === 'Tab' && e.target.name === "clave") {
            setLoadingData(true)
            setNotFoundedData(true)
            const clave = e.target.value;
            const response = await BuscarClientePorClave(clave);

            const familiaSeleccionada = familias.find((familia) => familia.clave === response.fam);
            const rutaPSeleccionada = rutasP.find( r=> r.rutaplanta?.trim().toUpperCase() === response.rutaplanta?.trim().toUpperCase() && r.planta === response.planta)
            setLoadingData(false)
            if (response && response?.length !== 0) {
                const mappedValues = {
                    ...initialValues,
                    ...response,
                    rutaplanta: rutaPSeleccionada ? rutaPSeleccionada.planta + '&&' + rutaPSeleccionada.rutaplanta : (response.planta ? response.planta : '') + '&&' + (response.rutaplanta ?  response.rutaplanta : ''),
                    fam: familiaSeleccionada ? familiaSeleccionada.clave : "",
                    numerocta: response.numerocta,
                    calleFacturacion: response.fcalle,
                    coloniaFacturacion: response.fcolonia,
                    ciudadFacturacion: response.fciudad,
                    estadoFacturacion: response.festado,
                    cpFacturacion: response.fcp,
                    servicio: response.serv,
                    refbanco: response.refbanco,
                    entreqcalles: response.entreqcalles,
                    telefonoFacturacion: response.ftelefono,
                    contactoFacturacion: response.fcontacto,
                    mtdopago: response.mtdopago,
                    frec: response.frec,
                    status: response.status || 'A', // Asigna 'A' si response.status está vacío


                };
                setInitialValues(mappedValues);
                Object.entries(mappedValues).forEach(([key, value]) => {
                    setFieldValue(key, value || '');
                });
                setNotFoundedData(false)
            } else {
                Object.entries(initialValues).forEach(([key]) => {
                    if (key !== 'clave')
                        setFieldValue(key, '');
                });
            }
        }
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    async function onSubmitAction(values) {
        const rutaPlanta = values.rutaplanta.split('&&').map(value => value)

        const updatedValues = {
            unidadnegocio: values.unidadnegocio,
            fnombre: values.fnombre,
            tiposociedad: values.tiposociedad,
            regimenfiscal: values.regimenfiscal,
            usocfdi: values.usocfdi,
            correoelectronico: values.correoelectronico,
            segundocorreoelectronico: values.segundocorreoelectronico,
            ruta: values.ruta,
            orden: values.orden,
            aalta: values.aalta,
            condspago: values.condspago,
            rfc: values.rfc,
            revision: values.revision,
            pago: values.pago,
            status: values.status,
            registro: values.registro,
            conts: values.conts ? parseInt(values.conts, 10) : null,
            paqinsm: values.paqinsm,
            planta: rutaPlanta[0],
            rutaplanta: rutaPlanta[1],
            replegal: values.replegal,
            nombre: values.nombre,
            numerocta: values.numerocta,
            calle: values.calle,
            colonia: values.colonia,
            ciudad: values.ciudad,
            estado: values.estado,
            cp: values.cp,
            entreqcalles: values.entreqcalles,
            telefono: values.telefono,
            serv: values.servicio,
            contacto: values.contacto,
            refbanco: values.refbanco,
            mtdopago: values.mtdopago,
            fcalle: values.calleFacturacion,
            fcolonia: values.coloniaFacturacion,
            fciudad: values.ciudadFacturacion,
            festado: values.estadoFacturacion,
            fcp: values.cpFacturacion,
            ftelefono: values.telefonoFacturacion,
            fcontacto: values.contactoFacturacion,
            frec: values.frec,
            fam: values.fam, // Incluye el valor de la familia

        };

        await runAction({
            promiseAction: async () => {
                //await sleep(3000)
                const response = await ActualizarCliente(values.clave, updatedValues);

                console.log("Respuesta de la API:", response.responseModificar.resp.clave);

                const claveRecibida = response.responseModificar.resp.clave; // Accede al lugar correcto
                if (claveRecibida === values.clave) {
                    console.log("Datos actualizados correctamente:", response);
                } else {
                    console.error("Error: La clave no coincide:", {
                        enviado: values.clave,
                        recibido: claveRecibida,
                    });
                    throw new Error("Error: La clave actualizada no coincide con la enviada.");
                }
            },
            title: "Actualización de Cliente",
            pendingText: "Actualizando cliente, por favor espere...",
            successText: "Cliente actualizado correctamente.",
            errorText: "Ocurrió un error al actualizar el cliente. Inténtalo nuevamente.",
        });
    }

    useEffect(() => {
        const selectStatus = document.querySelector("#selectStatus");
        if (selectStatus) Select.getOrCreateInstance(selectStatus);
        const selectRutaPlanta = document.querySelector("#selectRutaPlanta");
        if (selectRutaPlanta) Select.getOrCreateInstance(selectRutaPlanta);

        async function listarRutasP() {
            setLoadingData(true);
            const listaRutasP = await ObtenerRutasPorPlanta();
            setRutasP(listaRutasP);
            setLoadingData(false);
          }
      
        if (rutasP !== null && rutasP.length === 0) listarRutasP(); 
    }, []);

    return (
        <>
            {(loadingData || loadingAction) && <CircleSpiner />}
            <Formik
                initialValues={initialValues}
                validationSchema={schemaActualizarCliente} // Aquí se aplica el esquema de validación
                onSubmit={onSubmitAction}
            >
                {({ setFieldValue, values }) => (
                    <Form className="flex flex-col w-full p-2 gap-2">
                        <Accordion
                            titulo="Datos del Cliente"
                            idTarget="datosCliente"
                            expanded="true"
                        >
                            <div className="grid grid-cols-1 md:w-1/3 gap-2">
                                <InputText
                                    label="Clave"
                                    name="clave"
                                    type="text"
                                    onKeyDown={(e) => handleKeyDown(e, setFieldValue)}
                                    placeholder=""
                                />
                            </div>
                            <div className="grid-3">
                                <div className="md:col-span-2">
                                    <InputText
                                        label="Nombre del Cliente"
                                        name="nombre"
                                        type="text"
                                        placeholder=""
                                        disabled={notFoundedData}
                                    />
                                </div>
                                <InputText
                                    label="Unidad de Negocio"
                                    name="unidadnegocio"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />

                                <InputText
                                    label="Tipo de Sociedad"
                                    name="tiposociedad"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />

                                <InputText
                                    label="Correo Electrónico"
                                    name="correoelectronico"
                                    type="email"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Correo Electrónico Secundario"
                                    name="segundocorreoelectronico"
                                    type="email"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Ruta"
                                    name="ruta"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Orden"
                                    name="orden"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Año de Alta"
                                    name="aalta"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    disabled={true}
                                    label="Frecuencia"
                                    name="frec"
                                    type="text"
                                    placeholder=""
                                />

                                <InputText
                                    label="Condición de Pago"
                                    name="condspago"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="RFC"
                                    name="rfc"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Revisión de Facturas"
                                    name="revision"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Pago de Facturas"
                                    name="pago"
                                    type="text"
                                    disabled={notFoundedData}
                                />
                                <SelectF
                                    id="selectStatus"
                                    className="pt-[0.1235rem]"
                                    data-te-select-init
                                    name="status"
                                    disabled={notFoundedData}
                                >
                                    <option value="I">SUSPENDIDO</option>
                                    <option value="A">ACTIVO</option>
                                    <option value="B">BAJA DEFINITIVA</option>
                                </SelectF>
                                <InputText
                                    label="Registro INE"
                                    name="registro"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Contratos"
                                    name="contratos"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="No. Consultorios"
                                    name="conts"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Paquete de Insumos"
                                    name="paqinsm"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <SelectF
                                    id="selectFam"
                                    className="pt-[0.1235rem]"
                                    data-te-select-init
                                    label="Familia"
                                    name="fam"
                                    value={values.fam}
                                    disabled={notFoundedData}
                                >
                                    <option hidden defaultValue value="">
                                        Selecciona una Familia
                                    </option>
                                    {agSelectOptFam()}
                                </SelectF>
                                <SelectF
                                    id="selectRutaPlanta"
                                    disabled={notFoundedData}
                                    className="pt-[0.1235rem]"
                                    data-te-select-init
                                    label="Ruta Planta"
                                    name="rutaPlanta"
                                    value={values.rutaplanta}
                                    >
                                    <option hidden defaultValue value=""></option>
                                    {agSelectOptRutaPlanta(rutasP)}
                                </SelectF>
                            </div>
                        </Accordion>

                        <Accordion
                            titulo="Dirección para Recolección"
                            idTarget="direccionRecoleccion"
                            expanded="true"
                        >
                            <div className="grid-3">
                                <SelectF
                                    id="selectServ"
                                    className="pt-[0.1235rem]"
                                    data-te-select-init
                                    label="Servicio"
                                    name="servicio"
                                    disabled={notFoundedData}
                                >
                                    <option hidden defaultValue value=""></option>
                                    {agSelectOptServ(servs)}
                                </SelectF>
                                <InputText
                                    label="Ref. Bancaria" // Campo para referencia bancaria
                                    name="refbanco" // Asegúrate de que el nombre sea "refbanco"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <div className="md:col-span-2">
                                    <InputText
                                        label="Calle"
                                        name="calle"
                                        type="text"
                                        placeholder=""
                                        disabled={notFoundedData}
                                    />
                                </div>
                                <InputText
                                    label="Colonia"
                                    name="colonia"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Ciudad"
                                    name="ciudad"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Estado"
                                    name="estado"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="C.P."
                                    name="cp"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Entre Calles"
                                    name="entreqcalles"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Teléfono"
                                    name="telefono"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Contacto"
                                    name="contacto"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                            </div>

                        </Accordion>

                        <Accordion
                            titulo="Dirección para Facturación"
                            idTarget="direccionFacturacion"
                            expanded="true"
                        >
                            <div className="grid-3">
                                <div className="md:col-span-2">
                                    <InputText
                                        label="Nombre/Razon Social"
                                        name="fnombre"
                                        type="text"
                                        placeholder=""
                                        disabled={notFoundedData}
                                    />
                                </div>
                                <InputText
                                    label="No. Cuenta"
                                    name="numerocta"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Método de Pago"
                                    name="mtdopago"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Regimen Fiscal"
                                    name="regimenfiscal"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Uso de CFDI"
                                    name="usocfdi"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Calle de Facturación"
                                    name="calleFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Colonia de Facturación"
                                    name="coloniaFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Ciudad de Facturación"
                                    name="ciudadFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Estado de Facturación"
                                    name="estadoFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="C.P. de Facturación"
                                    name="cpFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Teléfono de Facturación"
                                    name="telefonoFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Contacto de Facturación"
                                    name="contactoFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                            </div>
                        </Accordion>
                        <div className="flex flex-col items-center justify-center">
                            <button
                                className="btn-primary mt-4 w-full md:w-1/6 mx-auto md:mx-0 center md:justify-end"
                                type="submit"
                            >
                                Actualizar Cliente
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ActualizarClienteForm;
