import React from "react";
import '../../Estilos/Menu/Menu.css'

const MenuItem = (props) => {
  return (
    <li className="relative pl-[3.4rem]">
      {props.md === true ? (
        <button type="button"
          onClick={props.callToAction}
          className="Text-Menu"
          data-te-sidenav-link-ref
        >
          {props.subMenuLabel}
        </button>
      ) : (
        <button type="button"
          onClick={props.callToAction}
          className="Text-Menu"
          data-te-sidenav-link-ref
          data-te-sidenav-toggle-ref
          data-te-target={props.idSideNav}
          aria-controls={props.idSideNav}
          aria-haspopup="true"
        >
          {props.subMenuLabel}
        </button>
      )}
    </li>
  );
};

export default MenuItem;
