import React from "react";
//import { Toast, initTE } from "tw-elements";
import { ToastContainer, toast, Icons, Bounce } from "react-toastify";

export function renderToastContainer(props) {

  return (
    <ToastContainer
      closeOnClick={props?.closeOnClick ?? false}
      closeButton={props?.closeButton ?? false}
    />
  );
}

const CustomToastify = (props) => {
  const { title, message, type, timeAutoClose, position, onOpenAction, onCloseAction } = props;

  return toast(
    <>
      <h1 className=" font-semibold">{title}</h1>
      <span className=" text-sm">{message}</span>
    </>,
    {
      type: type,
      position: position ?? "top-right",
      autoClose: timeAutoClose ?? 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      onOpen: () => onOpenAction ? onOpenAction() :  {},
      onClose: () => onCloseAction ? onCloseAction() :  {},
    }
  );
};

export const onPromiseToast = (props) => {
  const {
    promiseAction,
    pendingText,
    successText,
    errorText,
    title,
    timeAutoClose,
    position,
    onOpenAction,
    onCloseAction
  } = props;

  
    toast.onChange(v => {
      if(v.status === "removed" && onCloseAction){
       // run your callback
       onCloseAction()
      }
    })
  
  return toast.promise(promiseAction, {
    pending: {
      render() {
        return (
          <>
            <h1 className=" font-semibold">{title}</h1>
            <span className=" text-sm">{pendingText ?? "Ejecutando..."}</span>
          </>
        );
      },
      icon: Icons.spinner,
      position: position ?? "top-right",
      autoClose: timeAutoClose ?? 3000
    },
    success: {
      render({ data }) {
        return (
          <>
            <h1 className=" font-semibold">{title}</h1>
            <span className=" text-sm">
              {successText
                ? successText
                : data.responseMsj ?? "Proceso ejecutado correctamente."}
            </span>
          </>
        );
      },
      // other options
      icon: Icons.success,
      position: position ?? "top-right",
      autoClose: timeAutoClose ?? 5000
    },
    error: {
      render({ data }) {
        // When the promise reject, data will contains the error
        return (
          <>
            <h1>{title}</h1>
            <p>{errorText ?? data.message}</p>
          </>
        );
      },
      icon: Icons.error,
      position: position ?? "top-right",
      autoClose: timeAutoClose ?? 5000
    },
    onOpen: () => onOpenAction ? onOpenAction() :  {},
    onClose: () => onCloseAction ? onCloseAction() :  () => console.log('no onclose action'),
  });
};

export default CustomToastify;
