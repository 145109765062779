import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logoPlantilla from "../../imagenes/logo_plantilla.png";

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        fontFamily: 'Helvetica',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    headerContainer: {
        position: 'absolute',
        top: 20,
        left: 20,
        right: 20,
    },
    headerLeft: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 50,
        height: 50,
        marginRight: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        right: 20,
        fontSize: 10,
        textAlign: 'center',
    },
    content: {
        marginTop: 55,
        marginBottom: 110,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
        margin: 5,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableHeader: {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
        borderColor: '#000',
        borderWidth: 1,
    },
    tableColHeader: {
        borderColor: '#000',
        //       borderWidth: 1,
        fontSize: 8,
    },
    tableCol: {
        padding: 3,
        fontSize: 8,
    },
    lastCol: {
        borderRightWidth: 0,
    },
    tableCell: {
        margin: 'auto',
        fontSize: 9,
    },
    line: {
        width: '100%',
        height: 1,
        backgroundColor: 'black',
    },
    newTableColHeader: {
        borderColor: '#000',
        padding: 3,
        width: 40,
        textAlign: 'center',
        fontSize: 8,
    },
    newTableCol: {
        borderColor: '#000',
        borderWidth: 1,
        padding: 3,
        width: 40,
        height: 15,
        fontSize: 8,
    },
});

const BitacoraRuta = ({ data }) => {
    const { manifiestos, ruta, fecha, fInicio, nombre, oper, aux } = data;

    const registrosPorPagina = 17;
    const paginas = Array.from(
        { length: Math.ceil(manifiestos.length / registrosPorPagina) },
        (_, i) => manifiestos.slice(i * registrosPorPagina, (i + 1) * registrosPorPagina)
    );

    return (
        <Document>
            {paginas.map((pagina, pageIndex) => (
                pagina.length > 0 && (
                    <Page key={pageIndex} size="Letter" orientation="landscape" style={styles.page}>
                        <View style={styles.headerContainer} fixed>
                            <View style={styles.headerLeft}>
                                <Image style={styles.logo} src={logoPlantilla} />
                                <View>
                                    <Text>Sistema de Administración de Suvalsa</Text>
                                    <Text>Bitacora de viaje para la Ruta: {ruta} - {nombre} del: {fecha} al {fInicio}</Text>
                                    <Text>Vehículo: {manifiestos[0]?.manifVehiculo} | Marca: {manifiestos[0]?.marca} | Placas: {manifiestos[0]?.placas}</Text>
                                    <Text>Operador: {oper} Auxiliar:{aux}</Text>
                                </View>
                            </View>
                            <View style={styles.line} />
                        </View>
                        <View style={styles.footer} fixed>
                            <View style={styles.line} />
                            <Text>Observaciones________________________________________________________________________________________</Text>
                            <Text>_____________________________________________________________________________________________________</Text>
                            <Text>_____________________________________________________________________________________________________</Text>
                            <View style={styles.line} />
                            <Text style={{ fontSize: 7 }}>
                                Manifiesto bajo protesta de decir verdad que la informacion contenida en este documento es verdadera y fue realizada desarrollando el trabajo que la empresa me asigno en las fechas que aqui se indican unica y exclusivamente, por lo que firmo a continuacion para ratificar lo anterior
                            </Text>
                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                                <View style={{ flex: 1, marginRight: 5 }}>
                                    <Text style={{ fontSize: 8 }}>Operador Responsable:___________________________</Text>
                                    <Text style={{ fontSize: 8 }}>Operador Auxiliar y/o Personal de Apoyo:_________________</Text>
                                </View>
                                <View style={{ flex: 1, marginRight: 5 }}>
                                    <Text style={{ fontSize: 8 }}>Firma:__________________________________</Text>
                                    <Text style={{ fontSize: 8 }}>Firma:__________________________________</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontSize: 8 }}>Fecha:__________________________________</Text>
                                    <Text style={{ fontSize: 8 }}>Fecha:__________________________________</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.content}>
                            <View style={styles.table}>
                                <View style={[styles.tableRow, styles.tableHeader]}>
                                    <Text style={[{ flex: .3 }, styles.tableColHeader, styles.tableCell]}>FAM</Text>
                                    <Text style={[{ flex: 1 }, styles.tableColHeader, styles.tableCell]}>Nombre</Text>
                                    <Text style={[{ flex: .4 }, styles.tableColHeader, styles.tableCell]}>Ciudad</Text>
                                    <Text style={[{ flex: .2 }, styles.tableColHeader, styles.tableCell]}>Frec</Text>
                                    <Text style={[{ flex: .2 }, styles.tableColHeader, styles.tableCell]}>Tipo Serv</Text>
                                    <Text style={[{ flex: .4 }, styles.tableColHeader, styles.tableCell]}>Manifiesto</Text>
                                    <Text style={[{ flex: .4 }, styles.tableColHeader, styles.tableCell]}>Fecha</Text>
                                    <Text style={[{ flex: .2 }, styles.tableColHeader, styles.tableCell, styles.lastCol]}>Van Ins</Text>
                                    <Text style={[{ flex: .35 }, styles.newTableColHeader, styles.tableCell]}>Hora</Text>
                                    <Text style={[{ flex: .35 }, styles.newTableColHeader, styles.tableCell]}>Kms</Text>
                                    <Text style={[{ flex: .35 }, styles.newTableColHeader, styles.tableCell]}>Kgs</Text>
                                    <Text style={[{ flex: .35 }, styles.newTableColHeader, styles.tableCell]}>Cont</Text>
                                    <Text style={[{ flex: .35 }, styles.newTableColHeader, styles.tableCell]}>Firma</Text>
                                </View>
                                {pagina.map((item, index) => (
                                    <View style={styles.tableRow} key={index}>
                                        <Text style={[{ flex: .3 }, styles.tableCol, styles.tableCell]}>{item.clienteFam}</Text>
                                        <Text style={[{ flex: 1 }, styles.tableCol, styles.tableCell]}>{item.clienteNombre}</Text>
                                        <Text style={[{ flex: .4 }, styles.tableCol, styles.tableCell]}>{item.clienteCiudad}</Text>
                                        <Text style={[{ flex: .2 }, styles.tableCol, styles.tableCell]}>{item.manifSalida}</Text>
                                        <Text style={[{ flex: .2 }, styles.tableCol, styles.tableCell]}>{item.manifClave}</Text>
                                        <Text style={[{ flex: .4 }, styles.tableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .4 }, styles.tableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .2 }, styles.tableCol, styles.tableCell, styles.lastCol]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                    </View>
                                ))}
                            </View>
                        </View>
                    </Page>
                )
            ))}
        </Document>
    );
};

export default BitacoraRuta;