// Initialization for ES Users
import { Collapse, Ripple, initTE } from "tw-elements";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { deleteItem, getItem } from "../../utils/LocalStorage";
import ButtonShowMenu from "../Button/ButtonShowMenu";

export default function InternalHeader({ hiddenMenu, setMenuExpanded }) {
  const [userName] = useState(getItem("userName"));
  const navegar = useNavigate();

  function LogOut() {
    console.log("Click cerrandoSesion....");
    deleteItem("user");
    deleteItem("userName");
    navegar("/suvalsa/login");
  }

  useEffect(() => {
    initTE({ Collapse, Ripple });
  }, []);

  function onShowHide(){
    setMenuExpanded(hiddenMenu)
  }

  return (
    <header className="flex flex-col md:flex-row min-h-16 bg-primary-gradient justify-between w-full gap-2 shadow-lg px-2">
      <div className="flex justify-between md:justify-end items-end pb-0 md:pb-2 gap-4">
          {hiddenMenu &&
            <ButtonShowMenu onShowHide={onShowHide}/>
          }
      <div>
        <span className="header-title">Bienvenido a Suvalsa </span>
        {/* <span className="font-bold text-xl text-slate-50">{userName !=="" ? userName :  'Luis'}</span> */}
        <span className="font-bold text-xl text-slate-50">{userName}</span>
      </div>
      </div>

      <nav
        className="relative flex justify-end pb-2 bg-transparent text-blue-700 focus:text-neutral-700 dark:bg-neutral-600 dark:text-neutral-200 md:flex-wrap"
        data-te-navbar-ref
      >
        <div className="flex md:w-full flex-wrap items-end justify-between">
          <div
            className="!visible grow basis-[100%] items-center lg:!flex lg:basis-auto"
            id="navbarSupportedContentY"
            data-te-collapse-item
          >
            <ul
              className="mr-auto flex flex-row gap-4 items-center justify-end"
              data-te-navbar-nav-ref
            >
              <li data-te-nav-item-ref>
                <button
                  onClick={() => navegar("/suvalsa/home")}
                  className="header-link"                  data-te-nav-link-ref
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  Dashboard Principal
                </button>
              </li>

              <li className="pr-2" data-te-nav-item-ref>
                <button
                  onClick={() => LogOut()}
                  className="header-link"
                  data-te-nav-link-ref
                  data-te-ripple-init
                  data-te-ripple-color="primary"
                >
                  Cerrar Sesión
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
