import React, { useMemo, useRef, useState } from "react";
import useManifiestos from "./useManifiestos";
import AltaManifiestosTable from "./AltaManifiestos.table";
import Accordion from "../../../componentes/Accordion/Accordion";
import { InputText } from "../../../componentes/Formik";
import { Form, Formik } from "formik";
import schemaAltaManifiestosYdocsVi from "../Schemas/SchemaAltaManifiestos";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CustomModal from "../../../componentes/Modals/CustomModal";
import AgregarInsumoForm from "./AgregarInsumo.Form";
import ConsultarSalidasTable from "./ConsultarSalidas.table";
import "react-toastify/dist/ReactToastify.css";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";

const AltaManifiestosForm = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [columnCustomFilters, setCustomColumnFilters] = useState("");
  const {
    getDataFunction,
    BuscarRuta,
    loadingData,
    loadingAction,
    data,
    rowSelection,
    setRowSelection,
    dataRowSelected,
    setData,
    elaborarMasivo,
    lisatPaquetesInsumos,
    listaClientesMasivo, setListaClientesMasivo,
    idModalGenerarMasivos,
    idModalAgregarInsumo,
    idModalSalidas,
    addSalida,
    onSubmitAltaManifestos,
    buttonsGroup
  } = useManifiestos();

  const [clave, setClave] = useState();
  let fInicio = useRef("");
  let oper = useRef("");
  let aux = useRef("");
  let noEco = useRef("");
  let duracion = useRef("");
  let nombreRef = useRef("");

  const initValuesAdd = {
    ruta: "",
    nombre: "",
    dias: "",
    fInicio: "",
    oper: "",
    aux: "",
    noEco: "",
    operador: "",
    auxiliar: "",
    vehiculo: ""
  };

  const columnSize = 250;

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString",
        size: 50,
        enableSorting: true
      },
      {
        accessorKey: "ciudad",
        header: "Ciudad",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "familia",
        header: "Familia",
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize,
        enableSorting: false
      },
      {
        accessorKey: "ruta",
        header: "Ruta",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "orden",
        header: "Orden",
        enableColumnFilter: false,
        enableSorting: true,
        size: 100
      },
      {
        accessorKey: "servicio",
        header: "Servicio",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "dia",
        header: "Día",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "fvisita",
        header: "Fecha visita",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "salida",
        header: "Salida",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "manifiesto",
        header: "Manifiesto",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "planta",
        header: "Planta",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "rutaplanta",
        header: "Ruta Planta",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100,
      }
    ],
    []
  );

  const columnsMasiv = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString",
        size: 50,
        enableSorting: true
      },
      {
        accessorKey: "ruta",
        header: "Ruta",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "orden",
        header: "Orden",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "servicio",
        header: "Servicio",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "dia",
        header: "Día",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "fvisita",
        header: "Fecha visita",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
      {
        accessorKey: "salida",
        header: "Salida",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100
      },
    ],
    []
  );

  const onChangeClave = async (clave, setFieldValue) => {
    setLoadingPage(true);

    const data = await BuscarRuta(clave);
    console.log("data::::", data);

    setFieldValue("nombre", data.nombre);
    nombreRef.current = data.nombre;
    setFieldValue("dias", data.duracion);
    duracion.current = data.duracion
    setFieldValue("oper", data.oper);
    oper.current = data.oper
    setFieldValue("operador", data.operador);
    setFieldValue("aux", data.aux);
    aux.current = data.aux
    setFieldValue("auxiliar", data.auxiliar);
    setFieldValue("noEco", data.noEco);
    noEco.current = data.noEco
    setFieldValue("vehiculo", data.vehiculo);
    setFieldValue("claveSearch", '');
    setCustomColumnFilters([
      {
        id: "clave",
        value: ''
      }
    ]);
    if (clave !== "" && data.id !== "") getDataFunction(clave, fInicio.current);
    else setData([]);
    setLoadingPage(false);
  };

  return (
    <>
      {(loadingData || loadingAction || loadingPage) && <CircleSpiner />}
      <Formik
        initialValues={initValuesAdd}
        validationSchema={schemaAltaManifiestosYdocsVi}
        onSubmit={onSubmitAltaManifestos}
      >
        {(props) => {
          const { isSubmitting, setFieldValue, handleChange } = props;

          return (
            <Form className="flex flex-col w-full p-2 gap-2">
              <Accordion
                titulo="Datos del Viaje"
                idTarget="collapseManifDocsVForm"
                expanded="true"
              >
                <div className="grid-3">
                  <InputText
                    disabled={isSubmitting || loadingData}
                    value={clave}
                    label="Clave ruta"
                    name="clave"
                    type="number"
                    placeholder=""
                    onKeyDown={async (e) => {
                      if ((e.keyCode === 13 || e.keyCode === 9) && fInicio.current !== "") {
                        await onChangeClave(e.target.value,
                          setFieldValue);
                      }
                    }}
                    onChange={async (e) => setClave(e.target.value)}
                    onBlur={async (e) => {
                      setFieldValue("clave", e.target.value);
                      if (fInicio.current !== "") {
                        await onChangeClave(e.target.value,
                          setFieldValue);
                      }
                    }}
                  />
                  <InputText
                    disabled={loadingData}
                    label="Fecha de Inicio"
                    type="date"
                    placeholder=""
                    name="fInicio"
                    onChange={async (e) => {
                      fInicio.current = e.target.value;
                      handleChange(e);
                      if (clave && clave !== "") {
                        if (data && data.length)
                          await getDataFunction(clave, e.target.value);
                        else await onChangeClave(clave, setFieldValue);
                      }
                      setFieldValue("claveSearch", '');
                      setCustomColumnFilters([
                        {
                          id: "clave",
                          value: ''
                        }
                      ]);
                    }}
                  />
                  <InputText
                    disabled
                    label="Nombre"
                    name="nombre"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled
                    label="Días"
                    name="dias"
                    type="number"
                    placeholder=""
                  />
                  <InputText
                    disabled={true}
                    label="Operador"
                    name="oper"
                    type="text"
                    placeholder=""
                  />

                  <InputText
                    disabled={true}
                    label=""
                    name="operador"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={true}
                    label="Auxiliar"
                    name="aux"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={true}
                    label=""
                    name="auxiliar"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={true}
                    label="No.Eco."
                    name="noEco"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={true}
                    label="Vehículo"
                    name="vehiculo"
                    type="text"
                    placeholder=""
                  />
                  <button
                    disabled={isSubmitting || !(clave && clave !== '')}
                    type="button"
                    className="btn-secondary m-3"
                    onClick={async () => {
                      await onChangeClave(clave, setFieldValue);
                    }}
                  >
                    Buscar Manifiestos
                  </button>
                </div>
              </Accordion>
              <Accordion
                titulo={`Manifiestos en la Ruta ${clave ?? ""}`}
                idTarget="collapseClientesManifForm"
                expanded={data.length > 0 ? "true" : "false"}
                disabled={data.length <= 0}
              >
                <div className="flex flex-col w-full gap-2">
                  <div className="w-full md:w-1/4">
                    <InputText
                      disabled={loadingData || isSubmitting}
                      label="Buscar por clave"
                      name="claveSearch"
                      type="number"
                      placeholder=""
                      onChange={async (e) => {
                        handleChange(e);
                        setCustomColumnFilters([
                          {
                            id: "clave",
                            value: e.target.value
                          }
                        ]);
                      }}
                    />
                  </div>
                  <AltaManifiestosTable
                    clave={clave}
                    fInicio={fInicio}
                    columnsData={columns}
                    rutaData={{ duracion: duracion }}
                    data={data}
                    setData={setData}
                    getData={getDataFunction}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    columnCustomFilters={columnCustomFilters}
                  />
                  <span className=" text-sm text-red-500 font-bold text-left">
                    {Object.keys(rowSelection).length > 0 &&
                      (!dataRowSelected?.manifiesto &&
                        !(
                          dataRowSelected?.servicio === "CB" ||
                          dataRowSelected?.servicio === "CI" ||
                          dataRowSelected?.servicio === "TI")
                      ) ? "* Por favor, captura el número de manifiesto correspondiente" : ""
                    }
                  </span>
                </div>
              </Accordion>

              <div className="h-2" />
              <ButtonGroup
                buttonPrimary={buttonsGroup.buttonPrimary}
                buttonSecondary={buttonsGroup.buttonSecondary}
                buttonTertiary={buttonsGroup.getButtonTertiary(clave, fInicio)}
                buttonCuatriary={buttonsGroup.buttonCuatriary}
                buttonSextenary={buttonsGroup.buttonSextenary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
                buttonSevenary={buttonsGroup.buttonSevenary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
                buttonEightary={buttonsGroup.buttonEightary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
              />
            </Form>
          );
        }}
      </Formik>
      <CustomModal
        id={idModalAgregarInsumo}
        title={"Elaborar Manifiesto"}
        withForm
      >
        <AgregarInsumoForm
          ruta={clave}
          datosManif={{
            noManifiesto: dataRowSelected?.manifiesto,
            familia: dataRowSelected?.familia,
            cliente: dataRowSelected?.clave,
            orden: dataRowSelected?.orden,
            servicio: dataRowSelected?.servicio,
            fvisita: dataRowSelected?.fvisita,
            planta: dataRowSelected?.planta,
            rutaplanta: dataRowSelected?.rutaplanta
          }}
          datosViaje={{
            fInicio: fInicio.current,
            oper: oper.current,
            aux: aux.current,
            veh: noEco.current,
            duracion: duracion.current
          }}
          actionUpdateTable={addSalida}
          setLoading={setLoadingPage}
          idModal={idModalAgregarInsumo}
        />
      </CustomModal>
      <CustomModal
        id={idModalGenerarMasivos}
        title={"Elaborar Manifiestos Masivos"}
        withForm
      >
        <CustomGenericTable
          columnsData={columnsMasiv}
          data={listaClientesMasivo}
          optionsTable={{ ofText: "Clientes de" }}
          setData={setListaClientesMasivo}
        />
        <div className="w-full grid flex-row md:flex md:flex-row-reverse md:justify-start gap-4">
          <ButtonGroup
            buttonPrimary={
              {
                action: () => elaborarMasivo(listaClientesMasivo, lisatPaquetesInsumos, fInicio.current, async () => {
                  await getDataFunction(clave, fInicio.current)
                }),
                type: "button",
                label: "Elaborar"
              }
            }
          />
        </div>
      </CustomModal>
      <CustomModal
        id={idModalSalidas}
        title={`Salida de insumos - Clave ${dataRowSelected?.clave}`}
        withForm
      >
        <ConsultarSalidasTable
          noSalida={dataRowSelected?.salida} noManifiesto={dataRowSelected?.manifiesto}
          setLoading={setLoadingPage}
        />
      </CustomModal>
    </>
  );
};

export default AltaManifiestosForm;
