import React, { useCallback, useEffect, useMemo, useState } from "react";
// Initialization for ES Users
import { Modal, initTE } from "tw-elements";
import { ObtenerInsumos } from "../../../Apis/FetchInsumos";
//import { CrearSalida, ObtenerMaxSalida } from "../../../Apis/FetchSalidas";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
// import {
//     Generarmanifiesto,
//     ObtenerMaxManifiesto
// } from "../../../Apis/FetchManifiestos";
//import { useExecutePromise } from "../../../Hooks/UsePromiseAction";
//import dayjs from "dayjs";
//import CustomToastify from "../../../componentes/Toasts/CustomToast";
import { columnSize } from "../../../Constantes";
import SalidasManifiestoTable from "../Components/SalidasManifiesto";
import { useAppStore } from "../../../Stores/useAppStore";
import { CustomInputText } from "../../../componentes/Formik/InputText";

const EditarInsumoForm = (props) => {
    const msjErrorQuantity = 'La cantidad debe ser mayor a 0';
    const salidas = useAppStore(state => state.salidas);
    const setSalidas = useAppStore(state => state.setSalidas);
    //const addSalida = useAppStore(state => state.addSalida);
    const setShowSalidas = useAppStore(state => state.setShowSalidas);
    const showSalidas = useAppStore(state => state.showSalidas);

    //const [addInsumo, setAddInsumo] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [dataRowSelection, setDataRowSelection] = useState({});
    const [onLoadSalidas, setOnLoadSalidas] = useState(false);
    const [loading, setLoading] = useState(false);
    const [insumos, setInsumos] = useState([]);
    const [columnCustomFilters, setCustomColumnFilters] = useState("");

    const opcionesTabla = { ofText: "Insumos de" };

    const columnasData = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "Id",
                enableColumnFilter: false
                //footer: props => props.column.id,
                //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
            },
            {
                accessorKey: "clave",
                header: "Clave",
                enableColumnFilter: true,
                filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
                //filterFn: 'customFilter', //using our custom function filter
                //filterFn: "fuseFilterFn"
                //footer: props => props.column.id,
                size: 50, //set column size for this column
                enableSorting: false
            },
            {
                accessorKey: "nombre",
                header: "Nombre",
                enableColumnFilter: false,
                size: columnSize, //set column size for this column
                enableSorting: false
                //footer: props => props.column.id,
            },
            {
                accessorKey: "cantidad",
                header: "cantidad",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100 //set column size for this column
                //footer: props => props.column.id,
            },
            {
                accessorKey: "existencias",
                header: "Existencias",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100 //set column size for this column
                //footer: props => props.column.id,
            },
            {
                accessorKey: "modelo",
                header: "Modelo",
                enableColumnFilter: false,
                enableSorting: false,
                //footer: props => props.column.id,
                size: 100 //set column size for this column
            },
            {
                accessorKey: "unidad",
                header: "Uni",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100 //set column size for this column
                //footer: props => props.column.id,
            },
            {
                accessorKey: "tipo",
                header: "Tipo",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100 //set column size for this column
                //footer: props => props.column.id,
            }
        ],
        []
    );

    const TableCell = (getValue, row, id, table) => {
        const [value, setValue] = useState("");
        const [onError, setOnError] = useState(false);
        let initialValue = getValue();

        const isCantidad = id === "cantidad";
        // When the input is blurred, we'll call our table meta's updateData function
        const onBlur = () => {
            //console.log(getValue, row, id, table, value)
            table.options.meta?.updateData(row.index, id, value);
            //dataRowSelection.some( ins => ins.id === )
            //if(dataRowSelection[0] && (row.original.id === dataRowSelection[0].id)){
            setRowSelection((prevstate) => ({
                ...prevstate,
                [row.index]: true
            }
            ))
            // const newRow = {...row.original, cantidad : value};
            // const idxData = dataRowSelection.findIndex( rowSel => rowSel.clave === row.original.clave)
            // console.log(idxData)
            // //si se encuentra ya seleccionado, se actualiza
            // if(idxData>=0){
            //     setDataRowSelection((prevstate) => ({
            //         ...prevstate,
            //         [idxData]:newRow
            //     }))
            // }else{//si NO, se agrega
            //     setDataRowSelection(
            //         ...dataRowSelection,
            //         newRow
            //     )
            // }
            // setDataRowSelection((prevstate) => ({
            //     ...prevstate,
            //     ...{...row.original, cantidad : value}
            // }))
        };
        //establecer el valor de inicio, cuando se recuperan del servicio
        useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        // useEffect(() => {
        //     console.log(value)
        // }, [value]);
        //dependiendo de la validacion se setea como input o no
        return isCantidad ? (
            <>
                <input
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        listenerValidaNumber(e, setOnError);
                    }}
                    onBlur={onBlur}
                    type={"number"}
                    className={`${onError ? 'dataError' : ''}`}
                />
                {
                    onError ? 
                        <span className="dataError-Msj">{msjErrorQuantity}</span>
                    : ''
                }
            </>
        ) : (
            <span>{value}</span>
        );
    };

     /**
  * Funcion que valida si el numero ingresado es correcto, establece formato para la celda y Flag del mensaje de error
  * @param {*} e evento onValueChange
  * @param {*} setOnError hook para controlar la bandera de error sobre la celda o campo
  */
 const listenerValidaNumber = (e, setOnError) => {
    let isDataValid = false;
    let curval = Number(e.target.value);
    setTimeout(function () {
        // revisar si el valor de dias es mayor a 0 y menor a los dias establecidos en la ruta
        if (!isNaN(curval) && curval > 0) {
            isDataValid = true;
        }
        if (isDataValid) {
            console.log("valor numerico permitido");
            e.target.classList.remove("border-[1px]");
            e.target.classList.remove("border-red-600");
        } else {
            console.log(
                "valor no permitido. La cantidad debe ser numerico y mayor a 0"
            );
            e.target.innerHTML = "";
            e.target.classList.add("border-[1px]");
            e.target.classList.add("border-red-600");
            // CustomToastify({
            //     title: "Cantidad incorrecta",
            //     message: "La cantidad debe ser mayor a 0",
            //     type: "warning"
            // });
        }
        setOnError(!isDataValid);
    }, 100);
};

    const defaultColumn = {
        cell: ({ getValue, row, column: { id }, table }) => {
            return TableCell(getValue, row, id, table);
        }
    };

    const GetDataInsumos = useCallback(async () => {
        setLoading(true);
        const data = await ObtenerInsumos();
        let cInsumos = [];
        if (data !== null && data.length > 0) {
            cInsumos = data.map((res, index) => {
                const xSalida = salidas.filter( s => s.clave === res.clave)
                //console.log(res.clave,salidas, xSalida)
                const insumo = {
                    id: res.id,
                    clave: res.clave,
                    nombre: res.nombre,
                    modelo: res.modelo,
                    tipo: res.tipo,
                    existencias: res.existen ?? "",
                    unidad: res.unidad,
                    cantidad: xSalida.length > 0 ? xSalida[0].cantidad : 0
                };
                //si hay salidas, las mantiene seleccionadas cada que se carguen los insumos
                if(xSalida.length >0){
                    setRowSelection((prevstate) => ({
                        ...prevstate,
                        [index]: true
                    }
                    ))
                }
                return insumo;
            });
        }
        setInsumos(cInsumos);
        setLoading(false);
    }, [salidas]);

    async function onSubmitAddInsumo() {
        console.log("action add insumo: ", dataRowSelection);
        //en base al arreglo de insumos seleccionados se recupera la cantidad capturada en la tabla insumos
        //const newArrayAdd = dataRowSelection.map( row => {
        const newSalidas = dataRowSelection.map(row => {
            const currentSalida = salidas.find( s => s.clave === row.clave);
            let data = {}
            //si existe ya en las salidas, solo se actualiza la cantidad, si no se agrega nuevo
            if(currentSalida){
                data = {
                    ...currentSalida,
                    cantidad: row.cantidad,
                }
            }else{
                data = {
                    id: row.id,
                    clave: row.clave,
                    insumo: row.modelo,
                    //tipo: 'res.tipo',
                    //: 'res.existen',
                    unidad: row.unidad,
                    cantidad: row.cantidad,//dataSelected.cantidad,
                    cantidadActual: row.cantidad,
                    insumoId : row.clave
                }
            }
            // addSalida({
            //     ...data
            // })
            return data
        })
        //si deselecciona alguna de las salidas en la tabla de insumos, o agrega una nueva, reemplaza todo el arreglo de salidas con las nuevas seleccionadas
        setSalidas(newSalidas)
        //ir o ver la tabla de salidas
        setShowSalidas(true)
        ///para que no se recargue la tabla de salidas
        setOnLoadSalidas(false)
        return true;
    }
    /**
     * Accion para refrescar la tabla cada que se abre el modal en donde se encuentra cargada
     * y asi ver siempre los registros actualizados
     */
    useEffect(() => {
        const myModal = document.getElementById(props.idModal);
        initTE({ Modal });

        myModal.addEventListener("show.te.modal", () => {
            setOnLoadSalidas(true)
            setShowSalidas(true)
        });
    }, [props.idModal, setShowSalidas]);

    useEffect(() => {
        const myModal = document.getElementById(props.idModal);

        myModal.addEventListener("hide.te.modal", () => {
            console.log('>>>>>>>>>>> hideen modal')
            setSalidas([])
            setOnLoadSalidas(false)
            setRowSelection({})
        });
    }, [props.idModal, setSalidas]);

    useEffect(()=>{
        console.log(showSalidas)
        if(!showSalidas)
            GetDataInsumos();
    },[showSalidas])

    return (
        <>
            {(loading //|| loadingAction
            ) && <CircleSpiner />}
            {
                showSalidas ? (
                    <SalidasManifiestoTable onLoadSalidas={onLoadSalidas} editMode manifestData={props}
                        noSalida={props.datosManif.noSalida} noManifiesto={props.datosManif.noManifiesto}
                    />
                ) :
                    (
                        <div className="flex flex-col w-full gap-2">
                            <div className="w-full md:w-1/4">
                                <CustomInputText
                                    disabled={loading}
                                    label="Buscar por clave"
                                    name="claveSearch"
                                    type="number"
                                    placeholder=""
                                    onChange={async (e) => {
                                        setCustomColumnFilters([
                                            {
                                                id: "clave",
                                                value: e.target.value
                                            }
                                        ]);
                                    }}
                                />
                            </div>
                            <CustomGenericTable
                                columnsData={columnasData}
                                data={insumos}
                                optionsTable={opcionesTabla}
                                setData={setInsumos}
                                getData={GetDataInsumos}
                                defaultColumn={defaultColumn}
                                columnCustomFilters={columnCustomFilters}
                                isTableSelected={true}
                                isMultiRowSelection={true}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                setDataRowSelection={setDataRowSelection}
                            />
                            <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                <div className="w-full grid flex-row md:flex md:flex-row-reverse md:justify-start gap-4">
                                    {/* <span data-twe-modal-dismiss>
            </span> */}
                                    <button
                                        onClick={() => {
                                            setShowSalidas(true)
                                            setOnLoadSalidas(false)
                                        }
                                        }
                                        type="button"
                                        className="btn-secondary"
                                        disabled={insumos.length <= 0}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        onClick={onSubmitAddInsumo}
                                        type="button"
                                        className="btn-primary"
                                        disabled={insumos.length <= 0}
                                    >
                                        Agregar
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
            }

        </>
    );
};

export default EditarInsumoForm;
