import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { useTable } from "../../../componentes/Tables/useTable";
import CustomTable from "../../../componentes/Tables/CustomTable";
import { useRef } from "react";

const AltaManifiestosTable = ({
  clave,
  fInicio,
  columnsData,
  columnCustomFilters,
  rutaData,
  data,
  rowSelection,
  setRowSelection,
  setData,
  getData,
}) => {
  const msjErrorQuantity = 'El día debe ser mayor a 0';
  const msjErrorDay = 'El día debe ser menor a la duración del viaje';

  const isTableSelected = true;
  //const [table, setTable] = useState();

  const [globalFilter,] = React.useState("");
  const [columnFilters,] = useState("");
  // {
  //   id: 'clave',
  //   asc: true, // sort by clave in asc order by default
  // },

  const [sorting, setSorting] = React.useState([
    {
      id: "orden",
      asc: "true"
    }
  ]);

  let inputRefs = useRef([]);
  // Crear una matriz 2D de referencias para los inputs  
  useEffect(() => {
    console.log('data::::', data)
    inputRefs.current = Array.from({ length: data.length }, () =>
      Array.from({ length: 14 }, () => React.createRef())
    );
  }, [data])

  // Función para manejar la navegación con teclas
  const handleKeyDown = (e, rowIdx, colIdx, inputRefs) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const nextRow = rowIdx + 1 < inputRefs.length ? rowIdx + 1 : 0; // Ciclo al inicio si es la última fila
      if (inputRefs[nextRow] && inputRefs[nextRow][colIdx] && inputRefs[nextRow][colIdx].current) {
        inputRefs[nextRow][colIdx].current.focus();
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevRow = rowIdx - 1 >= 0 ? rowIdx - 1 : inputRefs.length - 1; // Ciclo al final si es la primera fila
      if (inputRefs[prevRow] && inputRefs[prevRow][colIdx] && inputRefs[prevRow][colIdx].current) {
        inputRefs[prevRow][colIdx].current.focus();
      }
    }
    // Puedes agregar manejo para ArrowLeft y ArrowRight si lo deseas
  };
  /**
* Funcion que valida si el numero ingresado es correcto, establece formato para la celda y Flag del mensaje de error
* @param {*} e evento onValueChange
* @param {*} setOnError hook para controlar la bandera de error sobre la celda o campo
*/
  const listenerValidaNumber = (e, setOnError) => {
    let isDataValid = false;
    let curval = Number(e.target.value);
    setOnError("")
    setTimeout(function () {
      // revisar si el valor de dias es mayor a 0 y menor a los dias establecidos en la ruta
      if (curval > 0 && curval <= rutaData.duracion.current) {
        isDataValid = true;
      }
      if (isDataValid) {
        console.log("valor numerico permitido");
        e.target.classList.remove("border-[1px]");
        e.target.classList.remove("border-red-600");
      } else {
        console.log(
          "valor no permitido. El valor debe ser numerico y mayor a 0 y menor a los dias establecidos en la ruta"
        );
        e.target.innerHTML = "";
        e.target.classList.add("border-[1px]");
        e.target.classList.add("border-red-600");
        if (curval <= 0) {
          setOnError(msjErrorQuantity)
        }
        if (curval > rutaData.duracion.current) {
          //notify("El valor debe ser mayor a 0 y menor a los días establecidos en la ruta", "Día incorrecto")
          // CustomToastify({
          //   title: "Día incorrecto", message: "El valor debe ser menor a los días establecidos en la ruta", type: 'warning'
          // })
          setOnError(msjErrorDay)
        }
      }
    }, 100);
  };

  const TableCell = (getValue, row, id, getIndex, table, fInicio) => {
    const [valueKey, setValueKey] = useState();
    const [value, setValue] = useState("");
    const [onError, setOnError] = useState();
    let initialValue = getValue();

    const isManif = id === "manifiesto";
    const isDia = id === "dia";
    const servicio = row.original.servicio;

    const colIndex = getIndex();

    const isEditable = (
      id === "manifiesto" && !(
        servicio === "CB" ||
        servicio === "CI" ||
        servicio === "TI")
    )
      ;

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      if (isDia) {
        const date = dayjs(fInicio);
        const days = Number(value);
        if (isNaN(days) || (!isNaN(days) && (days <= 0 || days > rutaData.duracion.current))) row.original.fvisita = "";
        else
          row.original.fvisita = date
            .add(days - 1, "days")
            .format("YYYY-MM-DD");
      }
      if (row.original.dia !== value)
        table.options.meta?.updateData(row.index, id, value);
    };
    //establecer el valor de inicio, cuando se recuperan del servicio
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);


    //dependiendo de la validacion se setea como input o no
    //return isDia && !row.original.salida && fInicio ? (
    return (isDia && (!row.original.salida || (!row.original.manifiesto)) && fInicio) ? (
      <>
        {
          inputRefs && inputRefs.current.length > 0 && inputRefs.current[row.index] && inputRefs.current[row.index].length > 0 && inputRefs.current[row.index][colIndex] ?
            (
              <>
                <input id={id + row.id + row.index}
                  ref={inputRefs.current[row.index][colIndex]}
                  value={value}
                  type={"number"}
                  className={`${onError ? 'dataError' : ''}`}
                  onKeyDown={(e) => {
                    console.log('target: ', e.target)
                    //handlerKeyArrows(e.target, e.key, "isDia")
                    handleKeyDown(e, row.index, colIndex, inputRefs.current)

                    console.log('key pressUp: ', e)
                    //ArrowDown ArrowUp
                    setValueKey(e.key)
                  }}
                  onChange={(e) => {
                    console.log(valueKey)
                    // 37. ArrowLeft, ArrowUp, ArrowRight,ArrowDown 
                    if ((valueKey === 'ArrowLeft' || valueKey === 'ArrowUp' || valueKey === 'ArrowRight' || valueKey === 'ArrowDown')) {
                      console.log(row.index)
                      return
                    }
                    setValue(e.target.value);
                    listenerValidaNumber(e, setOnError);
                  }}
                  onBlur={onBlur}
                />
                {onError ?
                  <span className="dataError-Msj">{onError}</span>
                  : ''}
              </>
            ) : <span className="block text-center">{value}</span>
        }
      </>
    ) : isEditable && inputRefs &&
      inputRefs.current.length > 0 && inputRefs.current[row.index] && inputRefs.current[row.index].length > 0 && inputRefs.current[row.index][colIndex] ? (
      <input id={id + row.id + row.index}
        ref={inputRefs.current[row.index][colIndex]}
        value={value}
        onKeyDown={(e) => {
          console.log('target: ', e.target)
          handleKeyDown(e, row.index, colIndex, inputRefs.current)
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={onBlur}
        type={"text"}
        className={`${isManif ? 'isManif' : ''}`}
      />
    ) : (
      <span className="block text-center">{value}</span>
    );
  };

  const defaultColumn = {
    cell: ({ getValue, row, column: { getIndex }, column: { id }, table }) => {
      return TableCell(getValue, row, id, getIndex, table, fInicio.current ?? fInicio);
    }
  };

  // function conditionalSelection(row) {
  //   return row.original.salida === "" || row.original.salida === null;
  // }

  const { table } = useTable({
    isSelected: isTableSelected,
    //isMultiRowSelection: true,
    setData,
    data,
    rowSelection,
    setRowSelection,
    columns: columnsData,
    defaultColumn,
    globalFilter,
    //conditionalSelection,
    columnFilters: columnCustomFilters ?? columnFilters,
    sorting,
    setSorting
  });

  // const crearFiltro = () => {
  //   const headerCl = table.getHeaderGroups().at(0).headers[1];
  //   console.log(headerCl);
  //   return (
  //     <div>
  //       <Filter column={headerCl.column} table={table} />
  //     </div>
  //   );
  // };

  useEffect(() => {
    table?.resetRowSelection();
  }, [data, table])

  return (
    <>
      {/* <div>
        <input
          value={globalFilter ?? ''}
          onChange={e => setGlobalFilter(e.target.value)}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search all columns..."
        />
      </div> */}
      <CustomTable
        table={table}
        setSorting={setSorting}
        actionRefreshData={() => getData(clave, fInicio.current)}
        optionsTable={{ ofText: "Manifiestos de" }}
        isTableSelected={isTableSelected}
      // tableOptions={
      //   {
      //     styles : {
      //       width: '100%', height: '600px'
      //     },
      //     classNames : 'overflow-auto'
      //   }
      // }
      />
    </>
  );
};

export function Filter(props) {
  const { column, table } = { ...props };

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={columnFilterValue?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={columnFilterValue?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={columnFilterValue ?? ""}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  );
}

export default AltaManifiestosTable;
