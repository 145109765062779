import FetchApi, { FetchApiPatch } from "./ConfigFetch";

const URL_INSUMOS = 'https://suvalsa-home.ddns.net:8030/insumos/'
const METHOD_LISTAR = 'listar';
const METHOD_ACT = "actualizar/";
const METHOD_BUSCAR = "ver/";
const METHOD_BUSCAR_CLAVE = "buscarPorClave/";
// GET
//https://suvalsa-home.ddns.net:8030/insumos/listar 
// {
//     "id": 6,
//     "CLAVE": "6",
//     "NOMBRE": "Bolsa de polietileno",
//     "MODELO": "Rojo de 60 x 80 cm",
//     "UNIDAD": "Pz",
//     "TIPO": "",
//     "EXISTEN": 0.0,
//     "PRECIO": 3.0,
//     "CANTIDAD": 0.0,
//     "MINIMO": null,
//     "MAXIMO": null,
//     "nombre": "Bolsa de polietileno",
//     "clave": "6",
//     "unidad": "Pz",
//     "tipo": "",
//     "modelo": "Rojo de 60 x 80 cm",
//     "minimo": null,
//     "cantidad": 0.0,
//     "maximo": null,
//     "existen": 0.0,
//     "precio": 3.0
//     },

//getAll
export async function ObtenerInsumos() {
    let dataInsumos = []

    dataInsumos = await FetchApi(URL_INSUMOS.concat(METHOD_LISTAR))

    return dataInsumos.data;
}
//getByid
export async function BuscarInsumo(idInsumo) {
    let dataInsumo = [];
  
    dataInsumo = await FetchApi(
        URL_INSUMOS.concat(METHOD_BUSCAR).concat(idInsumo)
    );

    return dataInsumo.data;
  }
  ///insumos/buscarPorClave/{clave}
  export async function BuscarInsumoPorClave(clave) {
    let dataInsumo = null;
  
    dataInsumo = await FetchApi(
        URL_INSUMOS.concat(METHOD_BUSCAR_CLAVE).concat(clave)
    );

    return dataInsumo.data;
  }
//Patch
//https://suvalsa-home.ddns.net:8030/insumos/actualizar/1
// {
//     "id": 1,
//     "CLAVE": "1",
//     "NOMBRE": "Bolsa de polietileno",
//     "MODELO": "Rojo de 20 x 30 cm",
//     "UNIDAD": "Pz",
//     "TIPO": "",
//     "EXISTEN": 15827.0,
//     "PRECIO": 0.25,
//     "CANTIDAD": 0.0,
//     "MINIMO": null,
//     "MAXIMO": null
// }
export async function ActualizarInsumo(clave, data) {
    const URL_PATCH = URL_INSUMOS.concat(METHOD_ACT).concat(clave);
    let responseModificar = null;
    responseModificar = await FetchApiPatch(URL_PATCH, JSON.stringify(data));
    // if(!responseModificar.headerResponse.statusOk){
    //     throw new Error(responseModificar.bodyResponse.error)
    // }

    return responseModificar;
}