import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Select } from "tw-elements";

import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import Accordion from "../../../componentes/Accordion/Accordion";
import { InputText, SelectFile } from "../../../componentes/Formik";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import useActualizarRuta from "./UseActualizarRuta";
import schemaActualizaRuta from "../Schemas/SchemaActualizarRuta";
import CustomModal from "../../../componentes/Modals/CustomModal";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import AgregarClientesTable from "./AgregarClientes.table";

const ActualizarRutaForm = () => {
  const [clave, setClave] = useState();

  const {
    dataFounded,
    loading,
    loadingData,
    loadingAction,
    AddData,
    newDataRows,
    rutaData,
    BuscarRuta,
    opcionesTabla,
    columnas,
    defaultColumn,
    clientesRuta,
    setClientesRuta,
    sorting, 
    setSorting,
    setDataRowSelection,
    getDataSelected,
    rowSelection,
    setRowSelection,
    initValuesAc,
    onSubmitActualizaRuta,
    idModalClientes,
    idModalRetirarCliente,
    agregarSelectAg,
    agregarSelectNoEco,
    noEco,
    noEco2,
    aux,
    oper,
    getDataFunction,
    confirmarEliminacion,
    buttonsGroup
  } = useActualizarRuta();

  useEffect(() => {
    const selectOper = document.querySelector("#selectOper");
    if (selectOper) Select.getOrCreateInstance(selectOper);
    const selectAux = document.querySelector("#selectAux");
    if (selectAux) Select.getOrCreateInstance(selectAux);
    const selectVehic1 = document.querySelector("#selectVehic1");
    if (selectVehic1) Select.getOrCreateInstance(selectVehic1);
    const selectVehic2 = document.querySelector("#selectVehic2");
    if (selectVehic2) Select.getOrCreateInstance(selectVehic2);
  }, []);

  const onChangeClave = async (clave, setFieldValue) => {
    const data = await BuscarRuta(clave);
    setFieldValue("nombre", data.nombre);
    setFieldValue("duracion", data.duracion);
    setFieldValue("oper", data.oper);
    setFieldValue("aux", data.aux);
    setFieldValue("noEco1", data.vehiculo1);
    //si se captura una clave y la consulta de la ruta si regresa info, se consultan los manifiestos de esa clave
    if (clave !== "" && data.id !== "") getDataFunction(clave);
    else setClientesRuta([]);
  };

  return (
    <>
      {(loading || loadingData || loadingAction) && <CircleSpiner />}
      <Formik
        initialValues={initValuesAc}
        validationSchema={schemaActualizaRuta}
        onSubmit={onSubmitActualizaRuta}
        validateOnChange={true}
        enableReinitialize
      >
        {(props) => {
          const { isSubmitting, values, setFieldValue } = props;
          return (
            <Form className="flex flex-col w-full p-2 gap-2">
              <Accordion
                titulo="Datos de la ruta"
                idTarget="collapseRutaDForm"
                expanded="true"
              >
                <div className="grid grid-cols-1 md:grid-cols-4 col-start-2 items-start gap-2 w-full">
                  <InputText
                    value={clave}
                    disabled={isSubmitting || loadingData}
                    label="Clave"
                    name="clave"
                    type="number"
                    placeholder=""
                    onKeyDown={async (e) => {
                      //presiona enter o tab
                      if (e.keyCode === 13 || e.keyCode === 9) {
                        await onChangeClave(e.target.value, setFieldValue);
                      }
                    }}
                    onChange={async (e) => {
                      setFieldValue("clave", e.target.value);
                      setClave(e.target.value);
                      if (clientesRuta.length && !e.target.value) {
                        await onChangeClave(e.target.value, setFieldValue);
                      }
                    }}
                  />
                  <InputText
                    disabled={isSubmitting || loadingData || !dataFounded}
                    label="Nombre"
                    name="nombre"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={isSubmitting || loadingData || !dataFounded}
                    label="Duración"
                    name="duracion"
                    type="number"
                    placeholder=""
                  />
                  <SelectFile
                    id="selectOper"
                    disabled={isSubmitting || loadingData || !dataFounded}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label="Operador"
                    name="oper"
                  >
                    <option hidden defaultValue value=""></option>
                    {agregarSelectAg("OPE", rutaData?.oper)}
                  </SelectFile>

                  <InputText
                    value={oper}
                    disabled={true}
                    label="Operador"
                    name="operador"
                    type="text"
                    placeholder=""
                  />
                  <SelectFile
                    id="selectAux"
                    disabled={isSubmitting || loadingData || !dataFounded}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label="Auxiliar"
                    name="aux"
                  >
                    <option hidden defaultValue value=""></option>
                    {agregarSelectAg("AUX", rutaData?.aux)}
                  </SelectFile>
                  <InputText
                    value={aux}
                    disabled={true}
                    label="Auxiliar"
                    name="auxiliar"
                    type="text"
                    placeholder=""
                  />
                  <SelectFile
                    id="selectVehic1"
                    disabled={isSubmitting || loadingData || !dataFounded}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label="No.Eco. 1"
                    name="noEco1"
                  >
                    <option hidden defaultValue value=""></option>
                    {agregarSelectNoEco()}
                  </SelectFile>
                  <InputText
                    value={noEco}
                    disabled={true}
                    label="Vehículo 1"
                    name="vehiculo1"
                    type="text"
                    placeholder=""
                  />
                  <SelectFile
                    id="selectVehic2"
                    disabled={isSubmitting || loadingData || !dataFounded}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label="No.Eco. 2"
                    name="noEco2"
                  >
                    <option hidden defaultValue value=""></option>
                    {agregarSelectNoEco()}
                  </SelectFile>
                  <InputText
                    value={noEco2}
                    disabled={true}
                    label="Vehículo 2"
                    name="vehiculo2"
                    type="text"
                    placeholder=""
                  />
                  <button
                    disabled={isSubmitting || !(clave && clave !== "")}
                    type="button"
                    className="btn-secondary m-3"
                    onClick={async () => {
                      await onChangeClave(clave, setFieldValue);
                    }}
                  >
                    Buscar ruta
                  </button>
                </div>
              </Accordion>
              <Accordion
                titulo={`Clientes en la Ruta ${rutaData?.clave ?? ""}`}
                idTarget="collapseClientesRutaDForm"
                expanded="true"
              >
                <div className="flex flex-col w-full gap-2">
                    <CustomGenericTable
                      data={clientesRuta}
                      newDataRows={newDataRows}
                      setData={setClientesRuta}
                      getData={() => getDataFunction(clave)}
                      optionsTable={opcionesTabla}
                      columnsData={columnas}
                      defaultColumn={defaultColumn}
                      isTableSelected={true}
                      rowSelection={rowSelection}
                      setRowSelection={setRowSelection}
                      setDataRowSelection={setDataRowSelection}
                      columnsHidden={["idClxruta"]}
                      sorting={sorting}
                      setSorting={setSorting}
                    />
                </div>
                <CustomModal
                  id={idModalClientes}
                  title={"Agregar Cliente"}
                  withForm
                >
                  <AgregarClientesTable clientesRuta={clientesRuta} actionUpdateTable={AddData} idModalClientes={idModalClientes}/>
                </CustomModal>
                <CustomModal
                  id={idModalRetirarCliente}
                  title={"Retirar Cliente de la Ruta"}
                  actionAccept={confirmarEliminacion}
                >
                  <span>¿Deseas retirar el cliente con la</span>
                  <h1>
                    clave {getDataSelected()?.clave ?? ""} de la ruta{" "}
                    {values.clave}?
                  </h1>
                </CustomModal>
              </Accordion>
              <ButtonGroup
                buttonPrimary={buttonsGroup.buttonPrimary}
                buttonSecondary={buttonsGroup.buttonSecondary}
                buttonTertiary={buttonsGroup.buttonTertiary}
                buttonCuatriary={buttonsGroup.buttonCuatriary}
                buttonQuintuary={buttonsGroup.buttonQuintuary}
              />
            
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ActualizarRutaForm;
