
import React from 'react'

export default function ButtonShowMenu({ onShowHide, targetId }) {
    return (
        <button
            className='ml-0 rounded bg-transparent py-1 px-4 text-white transition duration-500 ease-in-out hover:font-bold hover:bg-[#4a2cd1] active:bg-primary-800'
            data-te-sidenav-toggle-ref
            data-te-target={targetId ?? "#sideNavMenu"}
            aria-controls={targetId ?? "#sideNavMenu"}
            aria-haspopup="true"
            onClick={(e) => {
                e.preventDefault()
                onShowHide()
            }}
        >
            <span className="block text-xl [&>svg]:text-white">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-8 w-8"
                >
                    <path
                        fillRule="evenodd"
                        d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                        clipRule="evenodd"
                    />
                </svg>
            </span>
        </button>
    )
}
