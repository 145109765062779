import { useEffect, useState } from "react";
// Initialization for ES Users
import { Select } from "tw-elements";
import { ObtenerFamilias } from "../Apis/FetchFamilias";
import { createOption } from "../utils/CommonUtils";

const useFamilias = (selectFamId = undefined) => {

    const [familias, setFamilias] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    function agSelectOptFam() {
        return familias !== null
          ? familias.map((familia) =>
            createOption(familia.id, familia.clave, familia.clave + '   ' + familia.nombre)
          )
          : "";
      }

    useEffect(() => {
        async function listarFamilias() {
            setLoadingData(true)
            try {
                const listaFam = await ObtenerFamilias();
                setFamilias(listaFam);
            } catch (error) {
                console.error("Error al obtener las familias:", error);
            }
            setLoadingData(false)
        }

        listarFamilias();
    }, []);

    useEffect(() => {
        console.log("Setting Selects::: ");
        if (selectFamId) {
            const singleSelect = document.querySelector("#" + selectFamId);
            if (singleSelect) Select.getOrCreateInstance(singleSelect);
        }
    }, []);

    return {
        familias,
        agSelectOptFam,
        loadingData
    }
}

export default useFamilias;