import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Header from './HeaderServiceClientes';

function PlantillaReporte({ data }) {
    const itemsPerPage = 10; // Ajustar según sea necesario
    const numberOfPages = Math.ceil(data.length / itemsPerPage);

    const nombreFamilia = data.length > 0 ? data[0].fam : "";

    return (
        <Document>
            {Array.from({ length: numberOfPages }, (_, pageIndex) => (
                <Page size="A4" orientation="landscape" style={styles.page} key={pageIndex}>
                    <Header
                        familia={nombreFamilia}
                        pageNumber={pageIndex + 1}
                        totalPages={numberOfPages}
                    />

                    <View style={styles.table}>
                        <View style={styles.tableRowHeader}>
                            <Text style={styles.tableColHeader}>Clave</Text>
                            <Text style={styles.tableColHeader}>Familia</Text>
                            <Text style={styles.tableColHeader}>Cliente</Text>
                            <Text style={styles.tableColHeader}>Servicio</Text>
                            <Text style={styles.tableColHeader}>Frecuencia</Text>
                            <Text style={styles.tableColHeader}>Rep. Legal</Text>
                            <Text style={styles.tableColHeader}>RFC</Text>
                            <Text style={styles.tableColHeader}>Registro</Text>
                            <Text style={styles.tableColHeader}>Estado</Text>
                            <Text style={styles.tableColHeader}>Ciudad</Text>
                        </View>
                        {data.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage).map((item, index) => (
                            <View style={styles.tableRow} key={index} wrap={false}>
                                <Text style={styles.tableCol}>{item.manifclave}</Text>
                                <Text style={styles.tableCol}>{item.fam}</Text>
                                <Text style={styles.tableCol}>{item.nombre}</Text>
                                <Text style={styles.tableCol}>{item.serv}</Text>
                                <Text style={styles.tableCol}>{item.frec}</Text>
                                <Text style={styles.tableCol}>{item.replegal}</Text>
                                <Text style={styles.tableCol}>{item.rfc}</Text>
                                <Text style={styles.tableCol}>{item.registro}</Text>
                                <Text style={styles.tableCol}>{item.estado}</Text>
                                <Text style={styles.tableCol}>{item.ciudad}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            ))}
        </Document>
    );
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 25,
        marginHorizontal: 20, // Ajustar para mayor margen lateral
        marginVertical: 20 // Ajustar margen para evitar cortes
    },
    headerInfo: {
        marginBottom: 10,
    },
    headerText: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 4,
    },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 2,
        borderRightWidth: 1,
        borderBottomWidth: 1,
    },
    tableRowHeader: {
        flexDirection: 'row',
        backgroundColor: '#e4e4e4',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableCol: {
        flex: 1,
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        padding: 5,
        fontSize: 9,
        textAlign: 'center'
    },
    tableColHeader: {
        flex: 1,
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        padding: 5,
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'center'
    }
});

export default PlantillaReporte;