import React, { useState, useRef, useMemo } from 'react'
import { Form, Formik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "../../../componentes/Accordion/Accordion";
import { InputText, Select as SelectOpt } from "../../../componentes/Formik";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CustomModal from "../../../componentes/Modals/CustomModal";
import { useActualizarManifiestos } from "../Alta/useManifiestos";
import ActManifiestosTable from "./ActManifiestos.table";
import UseAgentesSelect from "../../../Hooks/UseAgentes";
import schemaAltaManifiestosYdocsVi from "../Schemas/SchemaAltaManifiestos";
import EditarInsumoForm from './EditarInsumos.Form';
import { columnSize } from '../../../Constantes';

const ActualizarManifiestosForm = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [columnCustomFilters, setCustomColumnFilters] = useState("");
  const {
    actionElaborar,
    getDataFunction,
    BuscarRuta,
    loadingData,
    data,
    dataSalidas,
    rowSelection,
    setRowSelection,
    setData,
    addSalida,
    idModalAgregarInsumo,
    onSubmitAltaManifestos,
    buttonsGroup, dataRowSelected
  } = useActualizarManifiestos();
  const [clave, setClave] = useState();
  let fInicio = useRef("");
  let oper = useRef("");
  let aux = useRef("");
  let noEco = useRef("");
  let duracion = useRef("");

  const initValuesAdd = {
    ruta: "",
    nombre: "",
    dias: "",
    fInicio: "",
    oper: "",
    aux: "",
    noEco: "",
    operador: "",
    auxiliar: "",
    vehiculo: ""
  };

  // const resetStates = () => {
  //   setFInicio("");
  //   setDias("");
  //   setGenManifiestos(false)
  // };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: true
      },
      {
        accessorKey: "ciudad",
        header: "Ciudad",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "familia",
        header: "Familia",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "ruta",
        header: "Ruta",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "orden",
        header: "Orden",
        enableColumnFilter: false,
        //footer: props => props.column.id,
        enableSorting: false,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "servicio",
        header: "Servicio",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "dia",
        header: "Día",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 75 //set column size for this column
      },
      {
        accessorKey: "fvisita",
        header: "Fecha visita",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "salida",
        header: "Salida",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "manifiesto",
        header: "Manifiesto",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "planta",
        header: "Planta",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "rutaplanta",
        header: "Ruta planta",
        enableColumnFilter: false,
        enableSorting: false,
        size: 150,
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const onChangeClave = async (clave, setFieldValue) => {
    setLoadingPage(true);

    const data = await BuscarRuta(clave);
    console.log("data::::", data);
    data.operador = buscarAgente("OPE", data.oper);
    data.auxiliar = buscarAgente("AUX", data.aux);

    setFieldValue("nombre", data.nombre);
    setFieldValue("dias", data.duracion);
    duracion.current = data.duracion
    setFieldValue("oper", data.oper);
    oper.current = data.oper
    setFieldValue("operador", data.operador);
    setFieldValue("aux", data.aux);
    aux.current = data.aux
    setFieldValue("auxiliar", data.auxiliar);
    setFieldValue("noEco", data.noEco);
    noEco.current = data.noEco
    setFieldValue("vehiculo", data.vehiculo);
    setFieldValue("claveSearch", '');
    setCustomColumnFilters([
      {
        id: "clave",
        value: ''
      }
    ]);
    //si se captura una clave y la consulta de la ruta si regresa info, se consultan los manifiestos de esa clave
    if (clave !== "" && data.id !== "") getDataFunction(clave, fInicio.current, true);
    else setData([]);
    setLoadingPage(false);
  };

  const { gettingData, agregarSelectAgDefault, buscarAgente,
    idAgenteOper, operador,
    idAgenteAux, auxiliar, } = UseAgentesSelect();

  const renderAgente = (id, name, label, value, puesto, isSubmitting) => {
    return (
      <>        
        <SelectOpt
          id={id}
          disabled={isSubmitting || gettingData}
          className="pt-[0.1235rem]"
          data-te-select-init
          label={label}
          name={name}
        >
          <option hidden defaultValue value=""></option>
          {agregarSelectAgDefault(puesto, puesto === 'OPE' ? oper.current : aux.current)}
        </SelectOpt>
        {
          puesto === 'OPE' ? (
            <InputText
              value={value}
              disabled={true}
              label=""
              name="operador"
              type="text"
              placeholder=""
            />
          ) : (
            <InputText
              value={value}
              disabled={true}
              label=""
              name="auxiliar"
              type="text"
              placeholder=""
            />
          )
        }

      </>
    )
  }

  const buttonActualizar = {
    action: actionElaborar,
    disabled:
      loadingData ||
      Object.keys(rowSelection).length <= 0 ||
      dataRowSelected?.salida === "" ||
      dataRowSelected?.fvisita === "",
    type: "button",
    label: "Actualizar"
  }

  return (
    <>
      {(loadingData || loadingPage) && <CircleSpiner />}
      <Formik
        initialValues={initValuesAdd}
        validationSchema={schemaAltaManifiestosYdocsVi}
        onSubmit={onSubmitAltaManifestos}
      >
        {(props) => {
          const { isSubmitting, setFieldValue, handleChange } = props;

          return (
            <Form className="flex flex-col w-full p-2 gap-2">
              <Accordion
                titulo="Datos del Viaje"
                idTarget="collapseManifDocsVForm"
                expanded="true"
              >
                <div className=" grid grid-cols-1 md:grid-cols-4 col-start-2 items-start gap-2 w-full mb-2">
                  <InputText
                    disabled={isSubmitting || loadingData}
                    value={clave}
                    label="Ruta"
                    name="clave"
                    type="number"
                    placeholder=""
                    onKeyDown={async (e) => {
                      //presiona enter o tab
                      if ((e.keyCode === 13 || e.keyCode === 9) && fInicio.current !== "") {
                        await onChangeClave(e.target.value,
                          setFieldValue);
                      }
                    }}
                    onChange={async (e) => setClave(e.target.value) }
                    onBlur={async (e) => {
                      setFieldValue("clave", e.target.value);
                      //setClave(e.target.value)
                      if (fInicio.current !== "") {//if ((data.length && !e.target.value) && fInicio.current !== "") {
                        await onChangeClave(e.target.value,
                          setFieldValue);
                      }
                    }}
                  />
                  <InputText
                    disabled={loadingData}
                    label="Fecha de Inicio"
                    type="date"
                    placeholder=""
                    name="fInicio"
                    onChange={async (e) => {
                      fInicio.current = e.target.value;
                      handleChange(e);
                      if (clave && clave !== "") {
                        //se valida si ya existen datos de viaje, solo se consultan los manfiestos en la ruta
                        if (data && data.length)
                          await getDataFunction(clave, e.target.value, true);
                        else await onChangeClave(clave, setFieldValue);// si no existen daos de viaje, se obtienen los datos y lista de los manifiestos
                      }

                      setFieldValue("claveSearch", '');
                      setCustomColumnFilters([
                        {
                          id: "clave",
                          value: ''
                        }
                      ]);
                    }}
                  />
                  <button
                    disabled={isSubmitting || !(clave && clave !== '' && fInicio.current !== "")}
                    type="button"
                    className="btn-secondary my-2 md:mx-0 md:mb-0"
                    onClick={async () => {
                      await onChangeClave(clave, setFieldValue);
                    }}
                  >
                    Generar Manifiestos
                  </button>
                </div>
                <div className=" grid grid-cols-1 md:grid-cols-4 col-start-2 items-start gap-2 w-full">
                  <InputText
                    disabled
                    label="Nombre"
                    name="nombre"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled
                    label="Días"
                    name="dias"
                    type="number"
                    placeholder=""
                  />
                  {renderAgente(idAgenteOper, operador.name, operador.label, operador.value, 'OPE', isSubmitting)}
                  {renderAgente(idAgenteAux, auxiliar.name, auxiliar.label, auxiliar.value, 'AUX', isSubmitting)}
                  <InputText
                    disabled={true}
                    label="No.Eco."
                    name="noEco"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={true}
                    label="Vehículo"
                    name="vehiculo"
                    type="text"
                    placeholder=""
                  />

                </div>
              </Accordion>
              <Accordion
                titulo={`Manifiestos en la Ruta ${clave ?? ""}`}
                idTarget="collapseClientesManifForm"
                expanded={data.length > 0 ? "true" : "false"}
                disabled={data.length <= 0}
              >
                <div className="flex flex-col w-full gap-2">
                  <div className="w-full md:w-1/4">
                    <InputText
                      disabled={loadingData || isSubmitting}
                      label="Buscar por clave"
                      name="claveSearch"
                      type="number"
                      placeholder=""
                      onChange={async (e) => {
                        handleChange(e);
                        setCustomColumnFilters([
                          {
                            id: "clave",
                            value: e.target.value
                          }
                        ]);
                      }}
                    />
                  </div>
                  <ActManifiestosTable
                    clave={clave}
                    fInicio={fInicio}
                    columnsData={columns}
                    rutaData={{ duracion: duracion }}
                    data={data}
                    setData={setData}
                    getData={getDataFunction}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    columnCustomFilters={columnCustomFilters}
                  />
                </div>
                <span className="text-sm text-red-500 font-bold text-left">
                  { Object.keys(rowSelection).length > 0 &&
                      ( !dataRowSelected?.manifiesto &&
                        !(
                         dataRowSelected?.servicio === "CB" ||
                         dataRowSelected?.servicio === "CI" ||
                         dataRowSelected?.servicio === "TI")
                     ) ? "* Por favor, captura el número de manifiesto correspondiente" : ""
                    }
                  </span>
              </Accordion>

              <div className="h-2" />
              <ButtonGroup
                buttonPrimary={buttonActualizar}
                buttonSecondary={buttonsGroup.buttonSecondary}
                buttonTertiary={
                  buttonsGroup.buttonTertiary(clave, fInicio)
                }
              />
            </Form>
          );
        }}
      </Formik>
      <CustomModal
        id={idModalAgregarInsumo}
        title={"Editar Manifiesto"}
        withForm
      >
        <EditarInsumoForm
          ruta={clave}
          datosManif={{
            id: dataRowSelected?.id,
            familia: dataRowSelected?.familia,
            cliente: dataRowSelected?.clave,
            orden: dataRowSelected?.orden,
            servicio: dataRowSelected?.servicio,
            fvisita: dataRowSelected?.fvisita,
            planta: dataRowSelected?.planta,
            rutaplanta: dataRowSelected?.rutaplanta,
            noSalida: dataRowSelected?.salida,
            noManifiesto: dataRowSelected?.manifiesto
          }}
          datosViaje={{
            fInicio: fInicio.current,
            oper: oper.current,
            aux: aux.current,
            veh: noEco.current,
            duracion: duracion.current
          }}
          actionUpdateTable={addSalida}
          setLoading={setLoadingPage}
          idModal={idModalAgregarInsumo}
          salidas={dataSalidas}
        />
      </CustomModal>
      {/* <CustomModal
        id={idModalSalidas}
        title={`Salida de insumos - Clave ${dataRowSelected?.clave}`}
        withForm
      >
        <ConsultarSalidasTable
          noSalida={dataRowSelected?.salida} noManifiesto={dataRowSelected?.manifiesto}
          setLoading={setLoadingPage}
        />
      </CustomModal> */}
    </>
  )
}

export default ActualizarManifiestosForm