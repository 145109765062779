import React from 'react'
import AltaSalidaAlmacenForm from './AltaSalidaAlmacen.Form'
import CardNavigate from '../../../../componentes/Cards/CardNavigate'

const AltaSalidaAlmacen = () => {
  return (
    <>
    <CardNavigate title="Alta de Paquetes de Salidas del Almacén">
      <AltaSalidaAlmacenForm />
    </CardNavigate>
  </>
  )
}

export default AltaSalidaAlmacen
